// 请求模块
import axios from "../utils/config"

// 1.获取表单基本信息
export const getBaseInfoApi = (id) => axios.get(`/ding/queryById?id=${id}`)
// 2.获取轨迹信息
// export const getWayApi = (id) => axios.get(`/ding/queryClockDetails?id=${id}`)
// /ding/upload
// 上传附件
export const getFujianApi = ((data)=>{
    var file = new FormData()
    Object.keys(data).forEach(key => {
        file.append(key, data[key])
    })
    return axios.post(`/ding/upload`, file, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
      });
        
})
// 提交
export const getSubmitApi = (data) => axios.post(`/ding/checkQueryProcess`,data)
// 查看审批流程
export const getLiuchengApi = (travelId) => axios.get(`/ding/queryApprovalLogByTraveId?travelId=${travelId}`)
// 出差数据  list
export const getListApi = (userId,pageNum,pageSize,userName,jobNumber,instanceId,startTime,endTime,state) => axios.get(`/ding/queryFlow?userId=${userId}&pageNum=${pageNum}&pageSize=${pageSize}&userName=${userName}&jobNumber=${jobNumber}&instanceId=${instanceId}&startTime=${startTime}&endTime=${endTime}&state=${state}`)





//加班接口
export const getJiabanInfoApi = (businessId) => axios.get(`/ding/jiaban/queryByBusinessId?businessId=${businessId}`)
// 加班审批流程
export const getJiabanLiuchengApi = (businessId) => axios.get(`/ding/jiaban/queryJiaBanByBusinessId?businessId=${businessId}`)
// 加班提交
export const getJiabanSubmitApi = (data) => axios.post(`/ding/jiaban/checkJiaBanProcess`,data)
// 加班数据  list
export const getJiabanListApi = (userId,pageNum,pageSize,userName,jobNumber,instanceId,startTime,endTime,state) => axios.get(`/ding/jiaban/queryAllJiaBan?userId=${userId}&pageNum=${pageNum}&pageSize=${pageSize}&userName=${userName}&jobNumber=${jobNumber}&instanceId=${instanceId}&startTime=${startTime}&endTime=${endTime}&state=${state}`)

export const getLoginApi = (code) => axios.get(`/ding/userInfo?code=${code}`)
// /ding/userInfo

// 判断是否管理员
export const getIsAdminApi = (userId) => axios.get(`/ding/queryAdmin?userId=${userId}`)
// 出差导出
export const getExportAdminOverApi = (data) => axios.post(`/ding/AdminExport`,data)


// 出差审批提醒
export const getSendOutApi = (startDate,endDate) => axios.get(`/ding/sendDingMsg?startDate=${startDate}&endDate=${endDate}`)
// 加班审批提醒
export const getSendOverApi = (startDate,endDate) => axios.get(`/ding/jiaban/sendDingMsg?startDate=${startDate}&endDate=${endDate}`)

export const getOutShuaApi = (startDate,endDate) => axios.get(`/ding/customQueryChuChai?startDate=${startDate}&endDate=${endDate}`)
export const getOverShuaApi = (startDate,endDate) => axios.get(`/ding/jiaban/customQueryJiaBan?startDate=${startDate}&endDate=${endDate}`)



// 管理维护列表
export const getKeepApi = (pageNum,pageSize) => axios.get(`/ding/managerconfiguration/list?pageNum=${pageNum}&pageSize=${pageSize}`)

// 管理员新增
export const getKeepAddApi = (data) => axios.post(`/ding/managerconfiguration`,data)
// 管理员编辑
export const getKeepEditApi = (data) => axios.put(`/ding/managerconfiguration`,data)
// 管理员删除
export const getKeepDelApi = (id) => axios.delete(`/ding/managerconfiguration/${id}`)


// 推送待办
export const getPushChuchaiApi = (data) => axios.post(`/ding/handPushChuChai`,data)
export const getPushJiabanApi = (data) => axios.post(`/ding/jiaban/handPushJiaBan`,data)





